import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { ErrorMsg } from '../models/error-msg.model';

export abstract class BaseService {

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  protected httpOptionsFormType = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    })
  };

  protected httpOptionsFormDataType = {
    headers: new HttpHeaders({
      'enctype': 'multipart/form-data'
    })
  };

  handleServerError(httpError: HttpErrorResponse) {
    let errorMsg: ErrorMsg;

    if (httpError.status === 0) {

      errorMsg = {
        key: 'No internet',
        value: ['Please check your internet connection'],
      };
    } else {
      errorMsg = httpError.error?.errors[0] ?? [];
    }
    return throwError(() => errorMsg);
  }
}
