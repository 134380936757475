import { Injectable } from '@angular/core'
import * as alertifyjs from 'alertifyjs'
import { ShowConfirmDialogParams } from '../../models/AlertifyConfirmationModal'
import { ErrorMsg } from '../models/error-msg.model';

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {

  /** This variable defines the position of the preview. */
  previewPosition = 'top-right';

  constructor() {
  }

  /**
   * This method shows a success message to the user.
   *
   * @param message The message to show.
   */
  showSuccess(message: string) {
    alertifyjs.set('notifier', 'position', this.previewPosition)
    alertifyjs.success(message)
  }

  /**
   * This method shows a warning message to the user.
   *
   * @param message The message to show.
   */
  showWarning(message: string) {
    alertifyjs.set('notifier', 'position', this.previewPosition)
    alertifyjs.warning(message)
  }

  /**
   * This method shows an error message to the user.
   *
   * @param message The message to show.
   * @deprecated Use the showError method
   */
  showErrorOld(message: string) {
    alertifyjs.set('notifier', 'position', this.previewPosition)
    alertifyjs.error(message)
  }

  showError(error: ErrorMsg | string, title: string = 'Error') {
    const msg = error instanceof Object ? error.value[0] : error;
    alertifyjs.set('notifier', 'position', this.previewPosition)
    alertifyjs.error(msg)
  }

  /**
   * This method shows a confirm dialog with the given message.
   * The successCallback will be called if the user clicks the confirm button,
   * and the errorCallback will be called if the user clicks the cancel button.
   *
   * @param options
   * @param successCallback
   * @param errorCallback
   */
  showConfirmDialog(options: ShowConfirmDialogParams, successCallback: () => void, errorCallback: () => void) {
    alertifyjs
      .confirm(
        options.message,
        successCallback,
        errorCallback
      )
      .set(options)
  }
}
