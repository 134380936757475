import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../shared/services/base.service';
import { environment } from 'src/environments/environment.development';
import { CreateTenantAndAdminUserModel } from '../models/user-account/create-tenant-and-admin-user.model';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { ResponseResult } from '../shared/models/response';
import { ConfirmResetPasswordInput, ConfirmSignUpInput, ResetPasswordInput, SignInInput, confirmResetPassword, confirmSignUp, decodeJWT, fetchAuthSession, getCurrentUser, resetPassword, signIn, signOut, resendSignUpCode, ResendSignUpCodeInput} from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import { SHA256, enc } from 'crypto-js';
import { SocialUserModel } from '../models/social-user';
import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { InviteeRegister } from '../settings/settings-pages/settings-users/models/invitee-register.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {

  adminAPIUrl = environment.adminResourceUrl;
  private authenticationSubject: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);

  //Social Login
  socialUserModel: SocialUserModel = {} as SocialUserModel;

  constructor(
    private http: HttpClient,
    private socialAuthService: SocialAuthService
  ) {
    super();
    Amplify.configure({
      Auth: {
        Cognito: {
          ...environment.cognito,
        }
      }
    });
  }

  //API Calls


  signupNewTenantAndUser(model: CreateTenantAndAdminUserModel): Observable<ResponseResult<string>> {
    return this.http.post<ResponseResult<string>>(`${this.adminAPIUrl}api/tenant-user`, model, this.httpOptions)
      .pipe(map(resp => resp), catchError(this.handleServerError));
  }

  public registerInvitedUser(model: InviteeRegister): Observable<void> {
    return this.http.post<void>(`${this.adminAPIUrl}api/tenant-user/register-invitee`, model, this.httpOptions)
      .pipe(map(resp => resp), catchError(this.handleServerError));
  }

  //Cognito SignUp 

  public async handleSignUpConfirmation({ username, confirmationCode }: ConfirmSignUpInput) {
    return await confirmSignUp({ username, confirmationCode });
  }

  public async handleResendCode({username}: ResendSignUpCodeInput){
    return await resendSignUpCode({username});
  }

  public async handleSignIn({ username, password }: SignInInput) {
    try {
      const response = await signIn({ username, password });
      if(response && response.isSignedIn === true){
        this.authenticationSubject.next(true);
      }
      return response
    } catch (error) {
      throw error;
    }
  }
  

  public async handleSignOut() {
    return await signOut()
      .then(() => {
        localStorage.clear();
        this.authenticationSubject.next(false);
      });
  }

  public async forgotPassword({ username }: ResetPasswordInput) {
    return await resetPassword({ username });
  }

  public async forgotPasswordSubmit({ username, newPassword, confirmationCode }: ConfirmResetPasswordInput) {
    return await confirmResetPassword({ username, newPassword, confirmationCode });
  }


  //Social Logins (NOT TESTED)

  IsUserExist(email: any): Observable<boolean> {
    return this.http.get<any>(environment.adminResourceUrl + "account/Accounts/isuserexists?email=" + email)
      .pipe(map(resp => {
        return resp;
      }));
  }

  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((x: any) => {return;});
  }

  public generatePasswordFromSub = (idToken: string): string => {

    const decodedToken = decodeJWT(idToken);
    const sub = decodedToken.payload.sub;

    const hashedSub = sub ? SHA256(sub).toString(enc.Hex) : '';

    let password = hashedSub.slice(0, 12);

    password += 'A';
    password += 'a';
    password += '1';
    password += '@';


    return password;
  };


  //AUTHENTICATION METHODS (NOT TESTED)

  public getUser(): Promise<any> {

    return getCurrentUser();
  }

  public isAuthenticated(): Promise<boolean> {
    if (this.authenticationSubject.value) {
      return Promise.resolve(true);
    } else {
      return this.getUser()
        .then((user: any) => {

          if (user) {
            return true;
          } else {
            return false;
          }
        }).catch(() => {
          return false;
        });
    }
  }


  public async setValues() {
    try {
      const userInfo = await this.getUser();
         } catch (error) {
      console.error('Failed to get user info', error);
    }
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }


  public async getNewTokens() {
    try {
      const currentSession = await this.getSession();
      const token_exp = currentSession.tokens.idToken.payload.exp;

      if ((token_exp * 1000) - 300 < Date.now()) {
        const { tokens } = await fetchAuthSession({ forceRefresh: true });
      }

    } catch (e) {
    }
  }


  public async getSession(): Promise<any> {
    return await fetchAuthSession();
  }

 
}
