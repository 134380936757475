import { inject } from '@angular/core';
import {
  CanActivateFn,
  Router,
} from '@angular/router';
import { AlertifyService } from 'src/app/shared/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';


export const authGuard: CanActivateFn = async (route, state) => {

  const router = inject(Router) as Router;
  const authService = inject(AuthService) as AuthService;
  const alertifyService = inject(AlertifyService) as AlertifyService;

  const isAuthenticated = await authService.isAuthenticated();

  if (isAuthenticated) {

    authService.getNewTokens();
    return true;

  } else {

    router.navigate(['/login'], { queryParams: { returnUrl: state.url } });

    alertifyService.showError("Please Login First");
    return false;
  }
};